.App {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
}

/* .App::before {
  position: absolute;
  top: 0;
  left: 0;
  content: '';
  display: block;
  width: 300%;
  height: 100%;
  background-image: url(./assets/bgs/bg1.png), url(./assets/bgs/bg2.png), url(./assets/bgs/bg3.png);
  background-repeat: no-repeat, no-repeat, no-repeat;
  background-position: 0% 0%, 50% 0%, right 0%;
  background-size: 33.33% 100%, 33.33% 100%, 33.33% 100%;
  z-index: -1;
  animation-name: App-bg-change;
  animation-duration: 3s;
  animation-timing-function: steps(2, end);
  animation-iteration-count: infinite;
}

@keyframes App-bg-change {
  from {
    transform: translateX(0);
  }
  end {
    transform: translateX(-100%);
  }
} */