.dokyo-app {
    width: 100%;
    height: 100%;
    position: relative;
}

.dokyo-app-header_logo {
    position: absolute;
    width: 183px;
    height: 56px;
    top: calc(50vh - 28px);
    left: calc(50vw - 91.5px);
    transition: top 280ms, left 280ms;
    pointer-events: none;
    user-select: none;
}

.dokyo-app-video {
    opacity: 0;
    transform: scale(0.6);
    transition: opacity 580ms, transform 580ms;
    transition-delay: 280ms;
}

.dokyo-app[data-loaded="true"] .dokyo-app-video {
    opacity: 1;
    transform: scale(1);
}

.dokyo-app[data-loaded="true"] .dokyo-app-header_logo {
    top: 75px;
    left: 119px;
}

@media only screen and (max-width: 768px) {
    .dokyo-app[data-loaded="true"] .dokyo-app-header_logo {
        top: 75px;
        left: calc(50vw - 91.5px);
    }
}

.dokyo-app-header_logo svg {
    width: 100%;
    max-height: 56px;
}

.dokyo-app-video {
    overflow: hidden;
    position: absolute;
    bottom: 120px;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: fit-content;
    pointer-events: none;
    user-select: none;
    min-width: 35vw;
    max-width: 60vh;
    min-height: 45vh;
    max-height: 70vh;
    padding: 50px 0;
}

@media only screen and (max-width: 768px) {
    .dokyo-app-video {
        width: 100vw;
        min-height: unset;
        max-height: 70vh;
        bottom: 10vh;
    }
}

.dokyo-app-video>video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    mix-blend-mode: screen;
    display:block;
    transform: scale(1.1);
    transform-origin: center;
}

.dokyo-app-video>img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    mix-blend-mode: screen;
    display:block;
    transform: scale(1.1);
    transform-origin: center;
    display: none;
}

.dokyo-app-video>img[data-show="true"] {
    display: block;
}